export default [
  {
    title: 'Gestion locative',
    icon: 'ListIcon',
    children: [
      {
        title: 'Tableau de bord & suivi',
        route: 'espace-prestataire-de-service.dashboard',
        icon: 'HomeIcon',
      },
      {
        title: 'Administration',
        icon: 'ListIcon',
      },
      {
        title: 'Comptabilité',
        icon: 'ListIcon',
      },
      {
        title: 'Rapport & analyses',
        icon: 'ListIcon',
      },

    ],
  },
  {
    title: 'Gestion de copropriété',
    icon: 'ListIcon',
    children: [
      {
        title: 'Administration & finances',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Ma copropriété',
            icon: 'DiscIcon',
          },
          {
            title: 'Membre Copropriété',
            icon: 'DiscIcon',
          },
          {
            title: 'Frais de copropriété',
            icon: 'DiscIcon',
          },
        ],
      },
      {
        title: 'Opérations',
        icon: 'DiscIcon',
        children: [
          {
            title: 'Planification des tâches',
            route: 'espace-prestataire-de-service.taches-entretien-planifie',
            icon: 'DiscIcon',
            
          },
        ],
      },
      {
        title: 'Communication',
        icon: 'DiscIcon',
      },
      {
        title: 'Rapport & analyses',
        icon: 'DiscIcon',
      },
      {
        title: 'Accès & sécurité',
        icon: 'DiscIcon',
      },
    ],
  },

]
